import { PlusOutlined } from '@ant-design/icons'
import { Button, Form, Input, InputNumber, Modal, Spin } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { IoCloseOutline } from 'react-icons/io5'
import { useDispatch } from 'react-redux'

import { getPlan, updatePlan } from '@/modules/plans/reducers/planReducer'
import { logError } from '@/utils/logger'

type Props = {
  planId: string | undefined
  show: boolean
  onSuccess?: () => void
  onClose: () => void
}

const ModalEditPlan = ({ onSuccess, planId, onClose, show }: Props) => {
  const myFieldRef = useRef<any>()
  const dispatch = useDispatch<any>()
  const [info, setInfo] = useState<any>()
  const [loading, setLoading] = useState<boolean>()

  const onFinish = async (formData: any) => {
    try {
      await dispatch(
        updatePlan({
          id: info.id,
          ...formData
        })
      ).unwrap()

      onClose()

      if (onSuccess) {
        onSuccess()
      }
    } catch (error: any) {
      logError(error)
    } finally {
      setLoading(false)
    }
  }

  const fetchData = async () => {
    setLoading(undefined)
    try {
      if (planId) {
        const data = await dispatch(
          getPlan({
            id: planId
          })
        ).unwrap()
        setInfo(data)
      }
    } catch (error: any) {
      // setErrorMessage('Đường dẫn không hợp lệ.')
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (show) {
      fetchData()
    }
  }, [show])

  /*
   *****************************************
   *
   *
   */

  return (
    <Modal
      title='Edit Plan'
      width={500}
      className='top-5'
      footer={false}
      onCancel={() => {
        onClose()
      }}
      open={show}
    >
      <div className='flex flex-col'>
        {loading === undefined ? (
          <div>
            <Spin />
          </div>
        ) : (
          <div className='pt-4'>
            <Form layout='vertical' onFinish={onFinish} initialValues={info} autoComplete='off'>
              <div className='flex flex-col pb-8'>
                <div className='flex flex-row gap-4'>
                  <Form.Item label='Name' name='name' className='flex-1'>
                    <Input ref={myFieldRef} />
                  </Form.Item>

                  <Form.Item label='Position' name='position' className='w-20'>
                    <InputNumber className='w-full' step={1} min={0} />
                  </Form.Item>
                </div>

                <Form.Item label='Description' name='description'>
                  <Input.TextArea rows={3} />
                </Form.Item>

                <div className='mb-2'>Features</div>
                <Form.List name='features'>
                  {(fields, { add, remove }, { errors }) => (
                    <div className='flex flex-col gap-2'>
                      {fields.map((field) => (
                        <Form.Item noStyle required={false} key={field.key}>
                          <div className='flex flex-row gap-1'>
                            <Form.Item {...field} noStyle>
                              <Input variant='outlined' />
                            </Form.Item>

                            <Button icon={<IoCloseOutline size={18} className='center' />} onClick={() => remove(field.name)} />
                          </div>
                        </Form.Item>
                      ))}

                      <Form.Item noStyle>
                        <Button type='dashed' onClick={() => add()} style={{ width: '100%' }} icon={<PlusOutlined />}>
                          Add field
                        </Button>
                        <Form.ErrorList errors={errors} />
                      </Form.Item>
                    </div>
                  )}
                </Form.List>
              </div>

              <div className='flex flex-row justify-end'>
                <Button loading={loading} type='primary' htmlType='submit'>
                  Update
                </Button>
              </div>
            </Form>
          </div>
        )}
      </div>
    </Modal>
  )
}

export default ModalEditPlan
