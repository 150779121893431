import { EPaymentMethod } from './enum'

export function getPaymentMethodString(method: EPaymentMethod): string {
  switch (method) {
    case EPaymentMethod.BANK:
      return 'Chuyển khoản ngân hàng'
    case EPaymentMethod.CASH:
      return 'Tiền mặt'
    case EPaymentMethod.MOMO:
      return 'Ví MoMo'
    default:
      return ''
  }
}
