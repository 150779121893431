/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button, Form, Input, message, Select, Spin } from 'antd'
import { isEmpty } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getUpdatedDiff } from '@/common/utils'
import type { RootState } from '@/store'
import { convertEnumToSelectOptions } from '@/utils/converter'

import { ESystemMode } from '../../common/enum'
import type { IConfig } from '../../reducers/settingReducer'
import { getAllConfig, updateConfig } from '../../reducers/settingReducer'

const GeneralSetting: React.FC = () => {
  const [form] = Form.useForm()
  const dispatch = useDispatch<any>()
  const config = useSelector((state: RootState) => state.setting)
  const [loading, setLoading] = useState(true)
  const [disabled, setDisabled] = useState(true)
  const [messageApi, contextHolder] = message.useMessage()

  const fetchConfig = async () => {
    try {
      await dispatch(getAllConfig())
    } catch (error: any) {
      messageApi.error(error.message)
    } finally {
      setDisabled(true)
      setLoading(false)
    }
  }

  const handleUpdate = async (values: IConfig) => {
    try {
      await dispatch(updateConfig(values))
      messageApi.success('Update successfully')
      setDisabled(true)
    } catch (error: any) {
      messageApi.error(error.message)
    }
  }

  const handleDataChange = (values: IConfig) => {
    const diff = getUpdatedDiff(config, values)
    setDisabled(isEmpty(diff))
  }

  const onFinish = (values: IConfig) => {
    const diff = getUpdatedDiff(config, values)

    if (isEmpty(diff)) {
      return
    }

    handleUpdate(values)
  }

  useEffect(() => {
    fetchConfig()
  }, [])

  if (loading) {
    return (
      <div className='center'>
        <Spin size='large' />
      </div>
    )
  }

  return (
    <Form form={form} layout='vertical' onFinish={onFinish} onValuesChange={handleDataChange} initialValues={config}>
      {contextHolder}
      <Form.Item label='Company Logo Url' name='CompanyLogo' rules={[{ required: true, message: 'Company Logo is required' }]}>
        <Input />
      </Form.Item>
      <Form.Item label='Company Name' name='CompanyName' rules={[{ required: true, message: 'Company Name is required' }]}>
        <Input />
      </Form.Item>
      <Form.Item label='System Mode' name='SystemMode' rules={[{ required: true, message: 'System Mode is required' }]}>
        <Select options={convertEnumToSelectOptions(ESystemMode)} />
      </Form.Item>
      <Form.Item label='Company Address' name='CompanyAddress' rules={[{ required: true, message: 'Company Address is required' }]}>
        <Input />
      </Form.Item>
      <Form.Item>
        <div className='flex justify-end'>
          <Button type='primary' htmlType='submit' disabled={disabled} loading={loading}>
            Save
          </Button>
        </div>
      </Form.Item>
    </Form>
  )
}

export default GeneralSetting
