/* eslint-disable import/no-cycle */
import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import thunk from 'redux-thunk'

import cacheReducer from '@/common/reducers/cacheReducer'
import meReducer from '@/common/reducers/meReducer'
import appReducer from '@/modules/app/reducers/appReducer'
import authReducer from '@/modules/auth/reducers/authReducer'
import invoiceReducer from '@/modules/invoices/reducers/invoiceReducer'
import packageReducer from '@/modules/plans/reducers/packageReducer'
import planReducer from '@/modules/plans/reducers/planReducer'
import settingReducer from '@/modules/settings/reducers/settingReducer'
import userReducer from '@/modules/users/reducers/userReducer'

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth', 'me', 'connection']
}

const rootReducer = combineReducers({
  auth: authReducer,
  me: meReducer,
  cache: cacheReducer,
  // App
  app: appReducer,
  invoice: invoiceReducer,
  user: userReducer,
  plan: planReducer,
  package: packageReducer,
  setting: settingReducer
})

const middlewares: any = [thunk]

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: middlewares,
  devTools: process.env.NODE_ENV !== 'production'
})

export const persistor = persistStore(store)

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
