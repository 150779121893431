import '@/styles/index.scss'

import type { FC } from 'react'
import React from 'react'
import { Route, Routes } from 'react-router-dom'

import Error404 from '@/layout/Error404'
import Layout from '@/layout/Layout'
import LoginPage from '@/modules/auth/LoginPage'
import HomePage from '@/modules/home/HomePage'

import GoogleCallbackPage from './modules/auth/GoogleCallbackPage'
import { InvoicesRoutes } from './modules/invoices/InvoicesRoutes'
import { PlansRoutes } from './modules/plans/PlansRoutes'
import { ProfileRoutes } from './modules/profile/ProfileRoutes'
import { SettingsRoutes } from './modules/settings/SettingsRoutes'
import { UsersRoutes } from './modules/users/UsersRoutes'

const App: FC = () => {
  const routesConfig = [
    ...SettingsRoutes,
    ...InvoicesRoutes,
    ...UsersRoutes,
    ...PlansRoutes,
    ...ProfileRoutes
    //
  ]

  const renderRoutes = (routes: any[]) =>
    routes.map((route) => (
      <Route key={route.path} path={route.path} element={route.element}>
        {route.children && renderRoutes(route.children)}
      </Route>
    ))

  return (
    <Routes>
      <Route path='/' element={<Layout />}>
        <Route index element={<HomePage />} />
        <Route path='login' element={<LoginPage />} />

        <Route path='oauth/google' element={<GoogleCallbackPage />} />

        {renderRoutes(routesConfig)}

        <Route path='*' element={<Error404 />} />
      </Route>
    </Routes>
  )
}

export default App
