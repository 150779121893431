import { ExclamationCircleFilled } from '@ant-design/icons'
import type { MenuProps } from 'antd'
import { Card, Dropdown, Modal } from 'antd'
import React, { useEffect, useMemo, useState } from 'react'
import { IoEllipsisVertical } from 'react-icons/io5'
import { useDispatch, useSelector } from 'react-redux'
import type { NavigateFunction } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'

import ModalAddPackage from '@/modules/plans/partials/package/components/ModalAddPackage'
import { fetchPackages } from '@/modules/plans/reducers/packageReducer'
import { deletePlan } from '@/modules/plans/reducers/planReducer'
import type { RootState } from '@/store'
import { logError } from '@/utils/logger'

import ListPackage from '../package/ListPackage'
import ModalEditPlan from './components/ModalEditPlan'

type Props = {
  info: any
}

const PlanItem: React.FC<Props> = ({ info }) => {
  const dispatch = useDispatch<any>()
  const navigate: NavigateFunction = useNavigate()
  const [loading, setLoading] = useState<boolean>(true)
  const [showModalEdit, setShowModalEdit] = useState<boolean>(false)
  const packages = useSelector((state: RootState) => {
    const plan = state.plan.items.find((item) => item.id === info.id)
    return plan ? plan.packages : []
  })

  const memoizedPackages = useMemo(() => packages, [packages])

  const items: MenuProps['items'] = [
    {
      label: 'Edit',
      key: 'edit'
    },
    {
      type: 'divider'
    },
    {
      label: 'Delete',
      danger: true,
      key: 'delete'
    }
  ]

  const fetchPackagesData = async () => {
    if (info?.id) {
      await dispatch(fetchPackages({ planId: info.id }))

      setLoading(false)
    }
  }

  const handleEditPlan = () => {
    setShowModalEdit(true)
  }

  const handleDeleteItem = async () => {
    try {
      await dispatch(deletePlan(info.id)).unwrap()
      navigate('/plans')
    } catch (error) {
      logError(error)
    }
  }

  const onClickMenu: MenuProps['onClick'] = ({ key }) => {
    if (key === 'edit') {
      handleEditPlan()
    } else if (key === 'delete') {
      Modal.confirm({
        title: 'Confirm',
        icon: <ExclamationCircleFilled />,
        content: 'Are you sure delete this item?',
        okText: 'Delete',
        okType: 'danger',
        cancelText: 'Cancel',
        onOk() {
          return handleDeleteItem()
        },
        onCancel() {}
      })
    }
  }

  useEffect(() => {
    fetchPackagesData()
  }, [])

  return (
    <Card
      loading={loading}
      type='inner'
      className='border-gray-200'
      title={info?.name}
      extra={
        <div className='flex flex-row items-center gap-4'>
          <ModalAddPackage planId={info.id} />

          <Dropdown menu={{ items, onClick: onClickMenu, className: 'w-32' }} placement='bottomRight' trigger={['click']}>
            <div className='h-8 w-8 cursor-pointer rounded border bg-white transition-colors center hover:shadow-card2'>
              <IoEllipsisVertical size={15} className='text-tertiary' />
            </div>
          </Dropdown>
        </div>
      }
      styles={{
        title: { fontSize: '20px' },
        header: {
          backgroundColor: '#F4F6FF'
        },
        body: { paddingInline: '32px' }
      }}
    >
      <ModalEditPlan
        planId={info.id}
        show={showModalEdit}
        onClose={() => {
          setShowModalEdit(false)
        }}
      />
      {packages && <ListPackage items={memoizedPackages} />}
    </Card>
  )
}

export default PlanItem
