import { isEqual } from 'lodash'

/*
 *****************************************
 *
 *
 */

export function getDefaultProfileAvatar(): string {
  return '/images/default/avatar-2.jpg'
}

export function getUpdatedDiff(originalObj: any, updatedObj: any): any {
  const diff = Object.keys(updatedObj).reduce((diffInfo, key) => {
    if (isEqual(originalObj[key], updatedObj[key])) {
      return diffInfo
    }

    return {
      ...diffInfo,
      [key]: updatedObj[key]
    }
  }, {})

  return diff
}

/*
 *****************************************
 *
 *
 */

export function sleep(ms: number): Promise<void> {
  return new Promise((resolve) => {
    setTimeout(resolve, ms)
  })
}
