import React from 'react'

import PackageDetail from './partials/package/components/PackageDetail'
import PlansIndex from './PlansIndex'

export const PlansRoutes = [
  {
    path: 'plans',
    element: <PlansIndex />,
    children: [{ path: 'package/:packageId', element: <PackageDetail /> }]
  }
]
