import type { PayloadAction } from '@reduxjs/toolkit'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import api from '@/common/api'

import type { EPlanType } from '../common/enum'

export interface PlanState {
  total: number
  items: any[]
  filter: {
    page: number
    limit: number
    sorts?: string
    search?: string
    createdAt?: string
  }
}

const initialState: PlanState = {
  total: 0,
  items: [],
  filter: {
    page: 1,
    limit: 25,
    sorts: '-createdAt',
    search: '',
    createdAt: ''
  }
}

export const fetchPlans = createAsyncThunk('plan/list', async (_, { rejectWithValue }) => {
  try {
    return await api.get('plans')
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

/*
 *****************************************
 *
 *
 */
type PlanCreateDto = {
  name: string
  price: number
  type: EPlanType
  operations: number
}

export const createPlan = createAsyncThunk('plan/create', async (payload: PlanCreateDto, { rejectWithValue }) => {
  try {
    const rs: any = await api.post('plans', payload)
    return rs
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

/*
 *****************************************
 *
 *
 */

type PlanGetDto = {
  id: string
}

export const getPlan = createAsyncThunk('plan/get', async (payload: PlanGetDto, { rejectWithValue }) => {
  try {
    const rs: any = await api.get(`plans/${payload.id}`)

    return { id: rs.id, ...rs }
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

/*
 *****************************************
 *
 *
 */
type PlanUpdateDto = {
  id: string
  name: string
  description?: string
  position?: string
}

export const updatePlan = createAsyncThunk('plan/update', async (payload: PlanUpdateDto, { rejectWithValue }) => {
  try {
    const { id, ...updateInfo } = payload
    const rs: any = await api.put(`plans/${id}`, updateInfo)
    return { id, ...rs }
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

/*
 *****************************************
 *
 *
 */

export const deletePlan = createAsyncThunk('plan/delete', async (planId: string, { rejectWithValue }) => {
  try {
    await api.delete(`plans/${planId}`)
    return {
      id: planId
    }
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

export const planSlice = createSlice({
  name: 'plan',

  initialState,

  reducers: {
    reset: (state) => {
      state.total = initialState.total
      state.items = initialState.items
      state.filter = JSON.parse(JSON.stringify(initialState.filter))
    },

    updateFilter(state, { payload }) {
      state.filter = { ...state.filter, ...payload }
    },

    setPackages(state, action: PayloadAction<{ planId: string; packages: any[] }>) {
      const { planId, packages } = action.payload
      const index = state.items.findIndex((item) => item.id === planId)
      if (index >= 0) {
        state.items[index] = { ...state.items[index], packages }
      }
    },
    addPackage(state, action: PayloadAction<{ planId: string; packageData: any }>) {
      const { planId, packageData } = action.payload
      const index = state.items.findIndex((item) => item.id === planId)
      if (index >= 0) {
        state.items[index].packages.push(packageData)
      }
    },
    deletePackage(state, action: PayloadAction<{ planId: string; packageId: string }>) {
      const { planId, packageId } = action.payload
      const index = state.items.findIndex((item) => item.id === planId)
      if (index >= 0) {
        const packageIndex = state.items[index].packages.findIndex((item: any) => item.id === packageId)
        if (packageIndex >= 0) {
          state.items[index].packages.splice(packageIndex, 1)
        }
      }
    },
    updatePackage(state, action: PayloadAction<{ planId: string; packageData: any }>) {
      const { planId, packageData } = action.payload
      const index = state.items.findIndex((item) => item.id === planId)
      if (index >= 0) {
        const packageIndex = state.items[index].packages.findIndex((item: any) => item.id === packageData.id)
        if (packageIndex >= 0) {
          state.items[index].packages.splice(packageIndex, 1, packageData)
        }
      }
    }
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchPlans.fulfilled, (state, action) => {
        const { total, items } = action.payload as any

        state.items = items.map((newPlan: any) => {
          const existingPlan = state.items.find((plan) => plan.id === newPlan.id)
          return existingPlan ? { ...newPlan, packages: existingPlan.packages } : newPlan
        })
        state.total = total
      })
      .addCase(createPlan.fulfilled, (state, action) => {
        const info = action.payload

        const index = state.items.findIndex((item) => item.id === info.id)

        if (index >= 0) {
          const newObject = Object.assign(state.items[index], info)
          state.items.splice(index, 1, newObject)
        } else {
          state.items.push(info)
        }
      })
      .addCase(updatePlan.fulfilled, (state, action) => {
        const info = action.payload as any

        const index = state.items.findIndex((item) => item.id === info.id)

        if (index >= 0) {
          const newObject = Object.assign(state.items[index], info)
          state.items.splice(index, 1, newObject)
        }
      })
      .addCase(deletePlan.fulfilled, (state, action) => {
        const { id } = action.payload

        const index = state.items.findIndex((item) => item.id === id)

        if (index >= 0) {
          state.items.splice(index, 1)
        }
      })
  }
})

export const { reset, updateFilter, setPackages, addPackage, deletePackage, updatePackage } = planSlice.actions
export default planSlice.reducer
