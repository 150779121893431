import React from 'react'

import UsersLayout from './layouts/UsersLayout'
import UserDetail from './UserDetail'
import UsersIndex from './UsersIndex'

export const UsersRoutes = [
  {
    path: 'users',
    element: <UsersLayout />,
    children: [
      { path: '', element: <UsersIndex /> },
      { path: ':id', element: <UserDetail /> }
    ]
  }
]
