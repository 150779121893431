import React from 'react'

import InvoiceDetail from './InvoiceDetail'
import InvoicesLayout from './layouts/InvoicesLayout'

export const InvoicesRoutes = [
  {
    path: 'invoices',
    element: <InvoicesLayout />,
    children: [{ path: ':id', element: <InvoiceDetail /> }]
  }
]
