import { DatePicker, Select } from 'antd'
import { endOfMonth, endOfWeek, endOfYear, startOfMonth, startOfWeek, startOfYear } from 'date-fns'
import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import type { RootState } from '@/store'

import { updateFilter } from '../reducers/invoiceReducer'

const { RangePicker } = DatePicker

const formatRange = (start: Date, end: Date) => {
  return [dayjs(start), dayjs(end)]
}

const calculateRange = (value: string | undefined, customRange?: any) => {
  const now = new Date()
  let newRange: any = null

  switch (value) {
    case 'today':
      newRange = [now, now]
      break
    case 'week':
      newRange = [startOfWeek(now, { weekStartsOn: 1 }), endOfWeek(now, { weekStartsOn: 1 })]
      break
    case 'month':
      newRange = [startOfMonth(now), endOfMonth(now)]
      break
    case 'year':
      newRange = [startOfYear(now), endOfYear(now)]
      break
    case 'custom':
      newRange = customRange || null
      break
    default:
      newRange = null
  }

  return newRange ? formatRange(newRange[0], newRange[1]) : null
}

const FilterByTime: React.FC = () => {
  const dispatch = useDispatch<any>()

  const filter = useSelector((state: RootState) => state.invoice.filter)

  const getInitialRange = () => {
    return calculateRange(filter.createdAt, filter.customRange)
  }

  const [range, setRange] = useState<any>(getInitialRange)

  const handleSelectChange = (value: string) => {
    const newRange = calculateRange(value, range)

    setRange(newRange)
    dispatch(updateFilter(value === 'custom' ? { createdAt: value, customRange: newRange } : { createdAt: value }))
  }

  const onRangeChange = (dates: any) => {
    setRange(dates)
    dispatch(updateFilter({ createdAt: 'custom', customRange: dates }))
  }

  useEffect(() => {
    if (filter.createdAt === 'custom' && filter.customRange) {
      setRange(filter.customRange)
    }
  }, [filter.createdAt])

  return (
    <div className='flex items-center rounded-sm border'>
      <Select
        value={filter.createdAt === 'custom' ? 'Custom' : filter.createdAt}
        style={{ width: 160 }}
        onChange={handleSelectChange}
        size='middle'
        variant='borderless'
        className='h-7 w-[120px] border-r'
        options={[
          { value: 'today', label: 'Today' },
          { value: 'week', label: 'This Week' },
          { value: 'month', label: 'This Month' },
          { value: 'year', label: 'This Year' }
        ]}
      />
      <RangePicker allowClear={false} size='middle' variant='borderless' className='h-7 w-[250px]' value={range} onChange={onRangeChange} />
    </div>
  )
}

export default FilterByTime
