import React from 'react'
import { BsCart3, BsPerson, BsReceipt, BsTv } from 'react-icons/bs'
import { IoSettingsOutline } from 'react-icons/io5'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import Logo from '@/components/Logo'
import type { RootState } from '@/store'

import MenuItem from './MenuItem'
import UserDropdown from './UserDropdown'

const Header = () => {
  const user = useSelector((state: RootState) => state.auth.user)

  const menus = [
    {
      id: 'home',
      name: 'Thống kê',
      to: '/',
      icon: <BsTv size={16} />
    },
    {
      id: 'invoices',
      name: 'Hóa đơn',
      to: '/invoices',
      icon: <BsReceipt size={16} />
    },
    {
      id: 'users',
      name: 'Người dùng',
      to: '/users',
      icon: <BsPerson size={16} />
    },
    {
      id: 'plans',
      name: 'Bảng giá',
      to: '/plans',
      icon: <BsCart3 size={16} />
    },
    {
      id: 'settings',
      name: 'Cài đặt',
      to: '/settings',
      icon: <IoSettingsOutline size={16} />
    }
  ]

  if (!user) {
    return <div />
  }

  return (
    <div className='fixed inset-x-0 top-0 z-50 flex h-[60px] flex-row bg-white shadow-sm'>
      <div className='flex w-40 flex-row items-center justify-start pl-5'>
        <Link to='/' className='flex flex-row center'>
          <Logo type='full' className='h-[30px]' />
        </Link>
      </div>

      <div className='container flex flex-1 flex-row items-center gap-1'>
        {menus.map((item: any) => {
          return <MenuItem key={item.id} name={item.name} to={item.to} icon={item.icon} />
        })}
      </div>

      <div className='flex w-40 flex-row justify-end pr-5'>
        <UserDropdown />
      </div>
    </div>
  )
}

export default Header
