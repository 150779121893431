import React from 'react'

import SettingsLayout from './layouts/SettingsLayout'
import GeneralSetting from './partials/general/GeneralSetting'
import OtherSetting from './partials/others/OtherSetting'

export const SettingsRoutes = [
  {
    path: 'settings',
    element: <SettingsLayout />,
    children: [
      {
        path: '', // default route
        element: <GeneralSetting />
      },
      {
        path: 'general',
        element: <GeneralSetting />
      },
      {
        path: 'others',
        element: <OtherSetting />
      }
    ]
  }
]
