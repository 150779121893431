export enum EUserStatus {
  INACTIVE = 1,
  ACTIVE = 2,
  SPAM = 3,
  BANNED = 4,
  TRASH = 5
}

export enum EUserPermissions {
  ISADMIN = 9999,
  MANAGE_LOGS = 1
}
