import { Button, Image, message } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import type { NavigateFunction } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'

import { googleAuth } from '@/modules/auth/reducers/authReducer'

type Props = {
  onSuccess?: () => void
  // redirectUrl?: string
}

const GoogleSignInButton = ({ onSuccess }: Props) => {
  const [messageApi, contextHolder] = message.useMessage()
  const navigate: NavigateFunction = useNavigate()
  const authWindow = useRef<any>()
  const timer = useRef<any>()

  const [loading, setLoading] = useState<boolean>(false)

  const dispatch = useDispatch<any>()

  const handleGoogleLogin = () => {
    setLoading(true)
    const oauthConfig = {
      clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
      redirectUri: `${process.env.REACT_APP_BASE_URL}/oauth/google`,
      scopes: ['email', 'profile'],
      authorizationUrl: 'https://accounts.google.com/o/oauth2/v2/auth',
      responseType: 'code'
    }

    const authUrl = new URL(oauthConfig.authorizationUrl)
    authUrl.searchParams.append('response_type', oauthConfig.responseType)
    authUrl.searchParams.append('client_id', `${oauthConfig.clientId}`)
    authUrl.searchParams.append('redirect_uri', oauthConfig.redirectUri)
    authUrl.searchParams.append('scope', oauthConfig.scopes.join(' '))
    authUrl.searchParams.append('state', 'google_login')

    const width = 550
    const height = 680
    const left = window.screenLeft + (window.innerWidth - width) / 2
    const top = window.screenTop + (window.innerHeight - height) / 2

    authWindow.current = window.open(
      authUrl.toString(),
      '_blank',
      `toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=${width},height=${height},left=${left},top=${top}`
    )

    timer.current = setInterval(() => {
      if (authWindow?.current.closed) {
        clearInterval(timer.current)
        setLoading(false)
      }
    }, 200)
  }

  useEffect(() => {
    return () => {
      if (timer.current) {
        clearInterval(timer.current)
      }

      if (authWindow.current) {
        authWindow.current.close()
      }
    }
  }, [])

  const handleGoogleAuthMessage = async (event: any) => {
    if (event.origin === window.location.origin) {
      const { code } = event.data
      const redirectUri = `${process.env.REACT_APP_BASE_URL}/oauth/google`

      if (code) {
        try {
          await dispatch(googleAuth({ code, redirect_uri: redirectUri })).unwrap()

          if (onSuccess) {
            onSuccess()
          } else {
            navigate('/')
          }
        } catch (e: any) {
          messageApi.open({
            type: 'error',
            content: e.message
          })

          // const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        } finally {
          authWindow.current?.close()
          setLoading(false)
        }
      }
    }
  }

  useEffect(() => {
    window.addEventListener('message', handleGoogleAuthMessage)
    return () => {
      window.removeEventListener('message', handleGoogleAuthMessage)
    }
  }, [])

  return (
    <>
      {contextHolder}
      <Button onClick={handleGoogleLogin} type='link' size='large' loading={loading} className='h-12 w-full rounded-lg border-2 border-[#EFEFEF] center'>
        <Image preview={false} src='/images/svg/google.svg' width={24} height={24} alt='ic-gg-oauth' className={loading ? 'hidden' : ''} />
        <span className='ml-2 font-semibold text-main'>{loading ? 'Signing in ...' : 'Login with Google'}</span>
      </Button>
    </>
  )
}

export default GoogleSignInButton
