import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import api from '@/common/api'

export interface IConfig {
  CompanyName: string
  SystemMode: string
  CompanyAddress: string
  CompanyLogo: string
}

const initialState: IConfig = {
  CompanyName: '',
  SystemMode: '',
  CompanyAddress: '',
  CompanyLogo: ''
}

/*
 *****************************************
 *
 *
 */

export const getAllConfig = createAsyncThunk('setting/fetch', async (_, { rejectWithValue }) => {
  try {
    const response = await api.get('configuration')
    if (response) {
      return response
    }
    return {}
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

/*
 *****************************************
 *
 *
 */

export const updateConfig = createAsyncThunk('setting/update', async (payload: IConfig, { rejectWithValue }) => {
  try {
    const response = await api.put('configuration', payload)
    if (response) {
      return payload
    }
    return {}
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

export const settingSlice = createSlice({
  name: 'setting',

  initialState,

  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(getAllConfig.fulfilled, (state, action) => {
        state = Object.assign(state, action.payload)
      })
      .addCase(updateConfig.fulfilled, (state, action) => {
        state = Object.assign(state, action.payload)
      })
  }
})

export default settingSlice.reducer
