import { Button, Form, InputNumber, Modal, Radio } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'

import { convertEnumToSelectOptions } from '@/utils/converter'
import { formatMoney, parseMoney } from '@/utils/formater'

import { EPackageBillingCycle } from '../../../common/enum'
import { createPackage } from '../../../reducers/packageReducer'

type Props = {
  planId: string
}

const ModalAddPackage = ({ planId }: Props) => {
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false)

  const myFieldRef = useRef<any>()
  const dispatch = useDispatch<any>()
  const [loading, setLoading] = useState<boolean>(false)

  const [form] = Form.useForm()

  const onFinish = async (formData: any) => {
    try {
      setLoading(true)
      await dispatch(
        createPackage({
          planId,
          ...formData
        })
      ).unwrap()

      setIsOpenModal(false)

      form.resetFields()
    } catch (error: any) {
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (isOpenModal) {
      form.resetFields()

      setTimeout(() => {
        myFieldRef.current.focus()
      }, 0)
    }
  }, [isOpenModal])

  return (
    <div>
      <Button
        type='primary'
        size='small'
        className='h-8'
        onClick={() => {
          setIsOpenModal(true)
        }}
      >
        Add Package
      </Button>

      <Modal
        title='Add Package  '
        className='top-10'
        width={440}
        footer={false}
        onCancel={() => {
          setIsOpenModal(false)
        }}
        open={isOpenModal}
      >
        <div className='flex flex-col pt-4'>
          <Form
            form={form}
            layout='vertical'
            onFinish={onFinish}
            autoComplete='off'
            initialValues={{
              billingCycle: EPackageBillingCycle.MONTHLY,
              operations: 1000,
              price: 1000
            }}
          >
            <div className='flex flex-1 flex-col'>
              <Form.Item label='Billing Cycle' name='billingCycle'>
                <Radio.Group options={convertEnumToSelectOptions(EPackageBillingCycle)} />
              </Form.Item>

              <Form.Item label='Operations' name='operations'>
                <InputNumber className='w-full' min={0} step={1000} ref={myFieldRef} formatter={formatMoney} parser={parseMoney} />
              </Form.Item>

              <Form.Item label='Price' name='price'>
                <InputNumber className='w-full' formatter={formatMoney} parser={parseMoney} />
              </Form.Item>
            </div>

            <div>
              <Button loading={loading} type='primary' htmlType='submit'>
                Add
              </Button>
            </div>
          </Form>
        </div>
      </Modal>
    </div>
  )
}

export default ModalAddPackage
