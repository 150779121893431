import type { ThemeConfig } from 'antd'

const color = {
  primary: '#5379FF',
  // primary: '#FF6D24',
  // primary: '#ff3e5f',

  border: '#e5e5e5', // '#dde2e8',

  main: '#111827',
  secondary: '#4B5563',
  tertiary: '#6B7280',
  quaternary: '#9CA3AF',

  error: '#eb5768',
  success: '#15C15D',

  light1: '#F4F4F4'
}

export const themeConfig: ThemeConfig = {
  token: {
    motionBase: 0.08,

    fontFamily: "'Inter Variable', sans-serif",

    colorPrimary: color.primary,

    colorTextBase: color.main,
    colorTextPlaceholder: color.quaternary,

    // controlHeight: 38,
    // lineWidth: 2,

    borderRadius: 5,
    borderRadiusLG: 6,
    colorBorder: color.border
  },

  components: {
    Input: {
      controlHeight: 40,
      colorBorder: color.border,
      colorBgContainer: '#ffffff',
      colorTextDisabled: color.tertiary,
      colorBgContainerDisabled: '#dde2e8'
    },

    InputNumber: {
      controlHeight: 40
    },

    Form: {
      colorTextHeading: color.main
    },

    Button: {
      boxShadow: 'none',
      controlHeight: 40,
      controlOutlineWidth: 0,
      colorBgBase: '#f4f4f4'
    },

    Select: {
      optionHeight: 28
    },

    DatePicker: {
      controlHeight: 40
    },

    Radio: {
      controlHeight: 40
    }
  }
}
