import React from 'react'

type Props = {
  className?: string
  type: 'circle' | 'circle-2' | 'full' | 'icon' | 'text'
}

const Logo = ({ type, className }: Props) => {
  return (
    <div className={className}>
      {type === 'full' && (
        <svg width='100%' height='100%' viewBox='0 0 435 128' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path
            d='M156.232 82.216L181.96 41.704V29.8H136.84V44.584H162.088L136.36 85.096V97H182.44V82.216H156.232ZM215.129 98.344C229.241 98.344 240.569 87.304 240.569 73C240.569 58.696 229.241 47.656 215.129 47.656C201.017 47.656 189.689 58.696 189.689 73C189.689 87.304 201.017 98.344 215.129 98.344ZM215.129 84.328C208.889 84.328 204.089 79.816 204.089 73C204.089 66.184 208.889 61.672 215.129 61.672C221.369 61.672 226.169 66.184 226.169 73C226.169 79.816 221.369 84.328 215.129 84.328ZM281.258 47.656C274.826 47.656 270.122 49.864 266.954 53.512V29.8H252.554V97H266.954V92.488C270.122 96.136 274.826 98.344 281.258 98.344C293.834 98.344 304.202 87.304 304.202 73C304.202 58.696 293.834 47.656 281.258 47.656ZM278.378 84.712C271.754 84.712 266.954 80.2 266.954 73C266.954 65.8 271.754 61.288 278.378 61.288C285.002 61.288 289.802 65.8 289.802 73C289.802 80.2 285.002 84.712 278.378 84.712ZM323.406 44.776C328.11 44.776 332.046 40.84 332.046 36.136C332.046 31.432 328.11 27.496 323.406 27.496C318.702 27.496 314.766 31.432 314.766 36.136C314.766 40.84 318.702 44.776 323.406 44.776ZM316.206 97H330.606V49H316.206V97ZM373.112 62.824V49H363.224V35.56L348.824 39.88V49H341.144V62.824H348.824V79.816C348.824 93.256 354.296 98.92 373.112 97V83.944C366.776 84.328 363.224 83.944 363.224 79.816V62.824H373.112ZM397.031 78.76H431.111C431.496 76.936 431.687 75.016 431.687 73C431.687 58.504 421.319 47.656 407.303 47.656C392.135 47.656 381.767 58.696 381.767 73C381.767 87.304 391.943 98.344 408.359 98.344C417.479 98.344 424.583 94.984 429.288 88.456L417.767 81.832C415.847 83.944 412.487 85.48 408.551 85.48C403.271 85.48 398.855 83.752 397.031 78.76ZM396.743 68.008C398.087 63.112 401.735 60.424 407.207 60.424C411.527 60.424 415.847 62.44 417.383 68.008H396.743Z'
            fill='#5379FF'
          />
          <path
            d='M100.73 124.777L65.596 63.9954L28.6646 127.91H59.9349L57.8682 124.409C57.1253 124.301 56.4164 124.027 55.7941 123.607C55.1719 123.188 54.6523 122.633 54.2739 121.985C53.6285 120.852 53.4552 119.511 53.7914 118.251C54.1277 116.992 54.9466 115.915 56.0711 115.253C57.2054 114.608 58.548 114.435 59.809 114.771C61.07 115.107 62.1481 115.925 62.8104 117.048C63.1836 117.698 63.402 118.424 63.4487 119.171C63.4955 119.918 63.3693 120.666 63.08 121.357L66.854 127.91H79.3442L68.4714 109.149C67.7285 109.04 67.0196 108.766 66.3973 108.347C65.775 107.927 65.2554 107.373 64.8771 106.725C64.2317 105.592 64.0584 104.25 64.3946 102.991C64.7309 101.731 65.5497 100.654 66.6743 99.9923C67.8085 99.3475 69.1512 99.1744 70.4122 99.5103C71.6732 99.8462 72.7513 100.664 73.4136 101.788C73.7867 102.437 74.0052 103.163 74.0519 103.911C74.0987 104.658 73.9725 105.406 73.6831 106.097L86.2632 127.91H98.8432C99.2189 127.906 99.5883 127.814 99.9215 127.641C100.225 127.476 100.475 127.226 100.64 126.923C100.814 126.59 100.906 126.221 100.91 125.846C100.953 125.665 100.959 125.477 100.928 125.293C100.898 125.11 100.83 124.934 100.73 124.777Z'
            fill='#5379FF'
          />
          <path d='M75.031 80.2434L65.596 63.9954L28.6646 127.91L75.031 80.2434Z' fill='url(#paint0_linear_916_279)' />
          <path
            d='M86.3532 1.15789L21.4761 113.368C21.3028 113.701 21.2104 114.07 21.2065 114.445C21.2104 114.82 21.3028 115.189 21.4761 115.522L28.6647 128L65.5961 63.9955L100.73 3.22255C100.904 2.88963 100.996 2.52059 101 2.14535C100.996 1.7701 100.904 1.40104 100.73 1.06812C100.565 0.76461 100.315 0.515261 100.012 0.349987C99.6783 0.17682 99.3089 0.0845415 98.9333 0.0806669H88.0605C87.7118 0.0727241 87.3684 0.166312 87.0721 0.349987C86.7448 0.526477 86.4903 0.812529 86.3532 1.15789Z'
            fill='#5379FF'
          />
          <path
            d='M28.6645 0.978468L14.5569 24.8568C14.3624 25.1813 14.2686 25.5562 14.2873 25.934C14.2912 26.3092 14.3836 26.6783 14.5569 27.0112C14.7473 27.3205 14.9906 27.594 15.2758 27.8191C15.6052 28.0022 15.9772 28.0951 16.3541 28.0884L25.5195 28.1782C25.8951 28.182 26.2646 28.2743 26.5978 28.4475C26.9307 28.6451 27.2087 28.9228 27.4065 29.2554C27.5799 29.5883 27.6722 29.9574 27.6761 30.3326C27.6722 30.7078 27.5799 31.0769 27.4065 31.4098L0.449287 76.7427C0.0898575 76.8324 0 77.1915 0 77.6404C0.00387848 78.0156 0.0962327 78.3846 0.269572 78.7176L14.8265 104.661C15.0168 104.97 15.2602 105.243 15.5453 105.468C15.8702 105.663 16.2454 105.756 16.6236 105.738C16.9993 105.734 17.3687 105.642 17.7019 105.468C18.0116 105.278 18.2853 105.035 18.5106 104.75L78.4456 3.67151C78.6401 3.34698 78.7339 2.97211 78.7151 2.59431C78.7113 2.21906 78.6189 1.85 78.4456 1.51708C78.2552 1.20776 78.0119 0.934298 77.7267 0.709175C77.4019 0.514828 77.0266 0.421091 76.6484 0.439854L30.5515 0C30.1759 0.00387462 29.8065 0.0961259 29.4732 0.269293C29.1471 0.432287 28.8686 0.676547 28.6645 0.978468Z'
            fill='#5379FF'
          />
          <defs>
            <linearGradient id='paint0_linear_916_279' x1='28.6646' y1='95.9528' x2='75.031' y2='95.9528' gradientUnits='userSpaceOnUse'>
              <stop stopOpacity='0.07' />
              <stop offset='1' stopOpacity='0.12' />
            </linearGradient>
          </defs>
        </svg>
      )}

      {type === 'icon' && (
        <svg width='100%' height='100%' viewBox='0 0 101 128' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path
            d='M100.73 124.777L65.596 63.9954L28.6646 127.91H59.9349L57.8682 124.409C57.1253 124.301 56.4164 124.027 55.7941 123.607C55.1719 123.188 54.6523 122.633 54.2739 121.985C53.6285 120.852 53.4552 119.511 53.7914 118.251C54.1277 116.992 54.9466 115.915 56.0711 115.253C57.2054 114.608 58.548 114.435 59.809 114.771C61.07 115.107 62.1481 115.925 62.8104 117.048C63.1836 117.698 63.402 118.424 63.4487 119.171C63.4955 119.918 63.3693 120.666 63.08 121.357L66.854 127.91H79.3442L68.4714 109.149C67.7285 109.04 67.0196 108.766 66.3973 108.347C65.775 107.927 65.2554 107.373 64.8771 106.725C64.2317 105.592 64.0584 104.25 64.3946 102.991C64.7309 101.731 65.5497 100.654 66.6743 99.9923C67.8085 99.3475 69.1512 99.1744 70.4122 99.5103C71.6732 99.8462 72.7513 100.664 73.4136 101.788C73.7867 102.437 74.0052 103.163 74.0519 103.911C74.0987 104.658 73.9725 105.406 73.6831 106.097L86.2632 127.91H98.8432C99.2189 127.906 99.5883 127.814 99.9215 127.641C100.225 127.476 100.475 127.226 100.64 126.923C100.814 126.59 100.906 126.221 100.91 125.846C100.953 125.665 100.959 125.477 100.928 125.293C100.898 125.11 100.83 124.934 100.73 124.777Z'
            fill='#5379FF'
          />
          <path d='M75.031 80.2434L65.596 63.9954L28.6646 127.91L75.031 80.2434Z' fill='url(#paint0_linear_916_309)' />
          <path
            d='M86.3532 1.15789L21.4761 113.368C21.3028 113.701 21.2104 114.07 21.2065 114.445C21.2104 114.82 21.3028 115.189 21.4761 115.522L28.6647 128L65.5961 63.9955L100.73 3.22255C100.904 2.88963 100.996 2.52059 101 2.14535C100.996 1.7701 100.904 1.40104 100.73 1.06812C100.565 0.76461 100.315 0.515261 100.012 0.349987C99.6783 0.17682 99.3089 0.0845415 98.9333 0.0806669H88.0605C87.7118 0.0727241 87.3684 0.166312 87.0721 0.349987C86.7448 0.526477 86.4903 0.812529 86.3532 1.15789Z'
            fill='#5379FF'
          />
          <path
            d='M28.6645 0.978468L14.5569 24.8568C14.3624 25.1813 14.2686 25.5562 14.2873 25.934C14.2912 26.3092 14.3836 26.6783 14.5569 27.0112C14.7473 27.3205 14.9906 27.594 15.2758 27.8191C15.6052 28.0022 15.9772 28.0951 16.3541 28.0884L25.5195 28.1782C25.8951 28.182 26.2646 28.2743 26.5978 28.4475C26.9307 28.6451 27.2087 28.9228 27.4065 29.2554C27.5799 29.5883 27.6722 29.9574 27.6761 30.3326C27.6722 30.7078 27.5799 31.0769 27.4065 31.4098L0.449287 76.7427C0.0898575 76.8324 0 77.1915 0 77.6404C0.00387848 78.0156 0.0962327 78.3846 0.269572 78.7176L14.8265 104.661C15.0168 104.97 15.2602 105.243 15.5453 105.468C15.8702 105.663 16.2454 105.756 16.6236 105.738C16.9993 105.734 17.3687 105.642 17.7019 105.468C18.0116 105.278 18.2853 105.035 18.5106 104.75L78.4456 3.67151C78.6401 3.34698 78.7339 2.97211 78.7151 2.59431C78.7113 2.21906 78.6189 1.85 78.4456 1.51708C78.2552 1.20776 78.0119 0.934298 77.7267 0.709175C77.4019 0.514828 77.0266 0.421091 76.6484 0.439854L30.5515 0C30.1759 0.00387462 29.8065 0.0961259 29.4732 0.269293C29.1471 0.432287 28.8686 0.676547 28.6645 0.978468Z'
            fill='#5379FF'
          />
          <defs>
            <linearGradient id='paint0_linear_916_309' x1='28.6646' y1='95.9528' x2='75.031' y2='95.9528' gradientUnits='userSpaceOnUse'>
              <stop stopOpacity='0.07' />
              <stop offset='1' stopOpacity='0.12' />
            </linearGradient>
          </defs>
        </svg>
      )}
    </div>
  )
}

export default Logo
