import { Button, Form, Input, Modal } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { HiPlus } from 'react-icons/hi2'
import { useDispatch } from 'react-redux'

import { createPlan } from '@/modules/plans/reducers/planReducer'

type Props = {
  onClose: () => void
}

const ModalAddPlan = ({ onClose }: Props) => {
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false)

  const myFieldRef = useRef<any>()
  const dispatch = useDispatch<any>()
  const [loading, setLoading] = useState<boolean>(false)

  const [form] = Form.useForm()

  const onFinish = async (formData: any) => {
    try {
      setLoading(true)
      await dispatch(
        createPlan({
          ...formData
        })
      ).unwrap()

      setIsOpenModal(false)

      onClose()
      form.resetFields()
    } catch (error: any) {
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (isOpenModal) {
      form.resetFields()

      setTimeout(() => {
        myFieldRef.current.focus()
      }, 0)
    }
  }, [isOpenModal])

  return (
    <div>
      <button
        className='absolute -bottom-9 right-0 h-10 w-10 cursor-pointer rounded-full border border-none bg-primary shadow transition-all center hover:bg-primary-400'
        onClick={() => {
          setIsOpenModal(true)
        }}
      >
        <HiPlus size={24} className='text-white' />
      </button>

      <Modal
        title='Add Plan'
        className='top-10'
        width={540}
        footer={false}
        onCancel={() => {
          setIsOpenModal(false)
        }}
        open={isOpenModal}
      >
        <div className='flex flex-col pt-4'>
          <Form form={form} layout='vertical' onFinish={onFinish} autoComplete='off'>
            <div className='flex flex-1 flex-col'>
              <Form.Item label='Name' name='name'>
                <Input ref={myFieldRef} />
              </Form.Item>

              <Form.Item label='Description' name='description'>
                <Input.TextArea rows={6} />
              </Form.Item>
            </div>

            <div>
              <Button loading={loading} type='primary' htmlType='submit'>
                Add
              </Button>
            </div>
          </Form>
        </div>
      </Modal>
    </div>
  )
}

export default ModalAddPlan
