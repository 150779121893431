import React from 'react'

import ProfileLayout from './layouts/ProfileLayout'
import ProfileChangePassword from './ProfileChangePassword'
import ProfileIndex from './ProfileIndex'

export const ProfileRoutes = [
  {
    path: 'profile',
    element: <ProfileLayout />,
    children: [
      { path: '', element: <ProfileIndex /> },
      { path: 'change-password', element: <ProfileChangePassword /> }
    ]
  }
]
