import { Button, Checkbox, Form, InputNumber, notification, Popconfirm, Radio, Spin } from 'antd'
import _, { isEmpty } from 'lodash'
import React, { useEffect, useState } from 'react'
import { BsTrash } from 'react-icons/bs'
import { useDispatch, useSelector } from 'react-redux'
import type { NavigateFunction } from 'react-router-dom'
import { useNavigate, useParams } from 'react-router-dom'

import { getUpdatedDiff } from '@/common/utils'
import type { RootState } from '@/store'
import { convertEnumToSelectOptions } from '@/utils/converter'
import { formatMoney, parseMoney } from '@/utils/formater'
import { logError } from '@/utils/logger'

import { EPackageBillingCycle } from '../../../common/enum'
import { deletePackage, getPackage, updatePackage } from '../../../reducers/packageReducer'

const PackageDetail: React.FC = () => {
  const navigate: NavigateFunction = useNavigate()
  const [api, contextHolder] = notification.useNotification()
  const dispatch = useDispatch<any>()

  const params = useParams()
  const [info, setInfo] = useState<any>({})
  const [loading, setLoading] = useState<boolean>(false)
  const [disabled, setDisabled] = useState<boolean>(true)
  const plans = useSelector((state: RootState) => state.plan.items)

  /*
   *****************************************
   *
   *
   */

  const fetchDetail = async () => {
    try {
      if (params.packageId) {
        const rs = await dispatch(getPackage(params.packageId)).unwrap()

        setInfo(rs)
      }
    } catch (error) {
      logError(error)
    }
  }

  useEffect(() => {
    if (params.packageId) {
      fetchDetail()
    }
  }, [params.packageId])

  const hideModal = async () => {
    navigate('/plans')
  }

  const onFinish = async (formData: any) => {
    const updatedInfo = getUpdatedDiff(info, formData)

    if (isEmpty(updatedInfo)) {
      hideModal()
      return
    }

    try {
      setLoading(true)
      await dispatch(
        updatePackage({
          id: info.id,
          ...updatedInfo
        })
      ).unwrap()

      setInfo(Object.assign(info, updatedInfo))

      hideModal()
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  const handleValuesChange = (changedValues: any, allValues: any) => {
    const updatedInfo = getUpdatedDiff(info, allValues)

    if (isEmpty(updatedInfo)) {
      setDisabled(true)
    } else {
      setDisabled(false)
    }
  }

  /*
   *****************************************
   *
   *
   */
  const handleDelete = async () => {
    try {
      if (params.packageId) {
        await dispatch(deletePackage(info)).unwrap()
        hideModal()
      }
    } catch (error) {
      logError(error)
    }
  }
  /*
   *****************************************
   *
   *
   */

  const handleChangeCheck = (e: any) => {
    const allPackages = _.flatMap(plans, 'packages')
    const otherPackages = _.filter(allPackages, (item) => item.id !== info.id)
    const hasDefault = _.some(otherPackages, { isDefault: true })
    if (e.target.checked && hasDefault) {
      api.warning({
        message: 'Warning',
        description: 'Should have only one default package.'
      })
    } else if (!e.target.checked && !hasDefault) {
      api.warning({
        message: 'Warning',
        description: 'At least one package should be default.'
      })
    }
  }

  return (
    <div className='flex flex-col pt-4'>
      {contextHolder}
      {info?.id ? (
        <Form layout='vertical' onFinish={onFinish} autoComplete='off' onValuesChange={handleValuesChange} initialValues={info}>
          <div className='flex flex-1 flex-col pb-8'>
            <Form.Item label='' name='billingCycle'>
              <Radio.Group options={convertEnumToSelectOptions(EPackageBillingCycle)} />
            </Form.Item>

            <div className='flex flex-row gap-4'>
              <Form.Item label='Operations' name='operations'>
                <InputNumber className='w-full' min={0} step={1000} formatter={formatMoney} parser={parseMoney} />
              </Form.Item>

              <Form.Item label='Price' name='price'>
                <InputNumber className='w-full' formatter={formatMoney} parser={parseMoney} />
              </Form.Item>
            </div>

            <Form.Item valuePropName='checked' noStyle name='isDefault'>
              <Checkbox onChange={handleChangeCheck}>Is default package?</Checkbox>
            </Form.Item>
          </div>

          <div className='flex flex-row items-center justify-between'>
            <Popconfirm
              title='Confirm'
              description='Are you sure to delete this package?'
              onConfirm={handleDelete}
              okText='Yes'
              okButtonProps={{
                type: 'primary',
                danger: true
              }}
              cancelText='No'
            >
              <Button icon={<BsTrash className='center' />} type='dashed' danger className='h-8' />
            </Popconfirm>

            <div className='flex flex-row gap-2'>
              <Button type='default' className='h-8' onClick={hideModal}>
                Cancel
              </Button>

              <Button disabled={disabled} loading={loading} type='primary' htmlType='submit' className='h-8'>
                Update
              </Button>
            </div>
          </div>
        </Form>
      ) : (
        <div className='flex flex-1 flex-col center'>
          <Spin />
        </div>
      )}
    </div>
  )
}

export default PackageDetail
