import React from 'react'
import { useDispatch } from 'react-redux'

import SearchInput from '@/components/SearchInput'

import { updateFilter } from '../reducers/userReducer'

function Search() {
  const dispatch = useDispatch<any>()

  const onSearch = (value: string) => {
    dispatch(
      updateFilter({
        search: value
      })
    )
  }

  return (
    <div className='w-[300px]'>
      <SearchInput onChange={onSearch} placeholder='Nhập từ khóa tìm kiếm' />
    </div>
  )
}

export default Search
