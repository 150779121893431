import { UserOutlined } from '@ant-design/icons'
import { Avatar, Divider } from 'antd'
import React from 'react'
import { BsKey, BsPersonBadge } from 'react-icons/bs'
import { useSelector } from 'react-redux'
import { Outlet } from 'react-router-dom'

import SecondSidebar from '@/components/SecondSidebar'
import type { RootState } from '@/store'

const SettingsLayoutDraft = () => {
  const user = useSelector((state: RootState) => state.auth.user)

  const sidebarItems = [
    { key: '', label: 'Profile', icon: <BsPersonBadge /> },
    { key: 'change-password', label: 'Password', icon: <BsKey /> }
  ]

  return (
    <main>
      <div className='container p-20 pt-8'>
        <div className='flex flex-row items-center gap-4'>
          <Avatar size={40} style={{ backgroundColor: '#c6c6c6' }} icon={<UserOutlined />} />
          <div className='line-clamp-1 text-xl font-bold text-main'>{user.fullname}</div>
        </div>

        <div className='flex pt-8'>
          <SecondSidebar items={sidebarItems} basePath='/profile' />
          <Divider type='vertical' className='mx-5 h-auto self-stretch' />
          <div className='flex-1'>
            <Outlet />
          </div>
        </div>
      </div>
    </main>
  )
}

export default SettingsLayoutDraft
