import { createSlice } from '@reduxjs/toolkit'

interface IState {
  lastRoute: string
}

const initialState: IState = {
  lastRoute: ''
}

/*
 *****************************************
 *
 *
 */

export const cacheSlice = createSlice({
  name: 'cache',

  initialState,

  reducers: {
    updateLastRoute(state, { payload }) {
      state.lastRoute = payload
    }
  }
})

export const { updateLastRoute } = cacheSlice.actions
export default cacheSlice.reducer
