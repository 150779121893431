import { UserOutlined } from '@ant-design/icons'
import type { MenuProps } from 'antd'
import { Avatar, Dropdown } from 'antd'
import React from 'react'
import { BsBoxArrowUpRight, BsPersonBadge, BsPower, BsQuestionCircle } from 'react-icons/bs'
import { useDispatch } from 'react-redux'
import type { NavigateFunction } from 'react-router-dom'
import { Link, useNavigate } from 'react-router-dom'

import { logout } from '@/modules/auth/reducers/authReducer'

const UserDropdown = () => {
  const dispatch = useDispatch<any>()
  const navigate: NavigateFunction = useNavigate()

  // const user = useSelector((state: RootState) => state.auth.user)

  const items: MenuProps['items'] = [
    {
      key: 'profile',
      className: 'py-2.5',
      icon: <BsPersonBadge size={18} />,
      label: <Link to='/profile'>Profile</Link>
    },
    // {
    //   key: '2',
    //   icon: <BsFingerprint size={18} />,
    //   className: 'py-2.5',
    //   label: <Link to='/settings/change-password'>Change password</Link>
    // },
    {
      key: '2',
      icon: <BsQuestionCircle size={18} />,
      className: 'py-2.5',
      label: (
        <Link to='https://zobite.com/help' target='_blank' className='flex flex-row items-center'>
          <div className='flex-1'>Help</div>

          <BsBoxArrowUpRight size={12} className='text-quaternary' />
        </Link>
      )
    },
    {
      key: 'logout',
      className: 'py-2.5 min-w-40 w-40',
      icon: <BsPower size={18} />,
      label: 'Logout'
    }
  ]

  /*
   *****************************************
   *
   *
   */

  const handleLogout = async () => {
    await dispatch(logout())
    navigate('/login')
  }

  const onClickDropdown: MenuProps['onClick'] = ({ key }) => {
    if (key === 'logout') {
      handleLogout()
    }
  }

  /*
   *****************************************
   *
   *
   */
  return (
    <Dropdown overlayClassName='border border-gray-100 rounded' trigger={['click']} menu={{ items, onClick: onClickDropdown }} placement='bottomRight' arrow>
      <div className='flex cursor-pointer select-none flex-row items-center gap-2 rounded p-2 py-2.5 transition-colors hover:bg-black/5'>
        <div className='rounded-full'>
          <Avatar size={32} style={{ backgroundColor: '#c6c6c6' }} icon={<UserOutlined />} />
        </div>
      </div>
    </Dropdown>
  )
}

export default UserDropdown
