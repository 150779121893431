import { List, Tooltip } from 'antd'
import React from 'react'
import type { NavigateFunction } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'

import { EPackageBillingCycle } from '@/modules/plans/common/enum'
import { getEnumKeyName } from '@/utils/converter'
import { formatMoney } from '@/utils/formater'

type Props = {
  items: any[]
}

const ListPackage: React.FC<Props> = ({ items }) => {
  const navigate: NavigateFunction = useNavigate()

  const onClickItem = (record: any) => {
    navigate(`/plans/package/${record.id}`)
  }

  if (!items?.length) {
    return <div className='center'>No package, please add new package</div>
  }

  return (
    <List
      rowKey={(item: any) => item.id}
      dataSource={items}
      renderItem={(item: any) => (
        <List.Item
          onClick={() => onClickItem(item)}
          className='flex cursor-pointer flex-nowrap justify-center gap-8 rounded-md px-3 transition-all hover:bg-gray-100'
        >
          <Tooltip placement='top' title='Number of Operations'>
            <div className='flex-1 border-r'>{formatMoney(item.operations)} Operations</div>
          </Tooltip>

          <Tooltip placement='top' title='Billing Cycle'>
            <div className='flex-1 border-r'>{getEnumKeyName(EPackageBillingCycle, item.billingCycle)}</div>
          </Tooltip>

          <Tooltip placement='top' title='Price'>
            <div className='flex-1 border-r'>{formatMoney(item.price)} đ/tháng</div>
          </Tooltip>

          <Tooltip placement='top' title='Is Default?'>
            <div className='w-[150px] md:w-[200px]'>{item.isDefault ? 'Default' : ''}</div>
          </Tooltip>
        </List.Item>
      )}
    />
  )
}

export default ListPackage
