import api from '@/common/api'

enum ELogLevel {
  URGENT = 1,
  ERROR = 2,
  DEBUG = 3,
  WARN = 4
}

interface ILogMeta {
  [key: string]: any
}

interface ILogPayload {
  level: number
  message: string
  meta: ILogMeta
}

async function log(payload: ILogPayload) {
  try {
    let metaInfo = {
      url: window.location.href,
      userAgent: navigator.userAgent,
      BUILD_NUMBER: process.env.REACT_APP_BUILD_NUMBER
    }

    if (payload.meta) {
      metaInfo = {
        ...metaInfo,
        ...payload.meta
      }
    }

    await api.post(`/logs`, {
      level: payload.level,
      message: payload.message,
      meta: JSON.stringify(metaInfo)
    })
  } catch (error) {}
}

/*
 *****************************************
 *
 *
 */

export async function logError(error: Error | string | any, meta: ILogMeta = {}) {
  try {
    let message: string
    let errorDetails: ILogMeta = {}

    if (typeof error === 'string') {
      message = error
    } else {
      message = error.message || 'N/A'
      errorDetails = {
        name: error.name,
        stack: error.stack,
        ...meta
      }
    }

    await log({
      message: message || 'N/A',
      level: ELogLevel.ERROR,
      meta: errorDetails
    })
  } catch (_error) {}
}

/*
 *****************************************
 *
 *
 */

export async function logDebug(error: Error | string, meta: ILogMeta = {}) {
  try {
    let message: string
    let errorDetails: ILogMeta = {}

    if (typeof error === 'string') {
      message = error
    } else {
      message = error.message || 'N/A'
      errorDetails = {
        name: error.name,
        stack: error.stack,
        ...meta
      }
    }

    await log({
      message: message || 'N/A',
      level: ELogLevel.DEBUG,
      meta: errorDetails
    })
  } catch (_error) {}
}
