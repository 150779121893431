import { format, isSameYear, isToday, isYesterday } from 'date-fns'

export const formatMoney = (value: any) => {
  // Format the input value as currency (add commas, decimals, etc.)
  return `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const parseMoney = (value: any) => {
  // Parse the formatted money value to a numeric value
  return value.replace(/\$\s?|(,*)/g, '')
}

/*
 *****************************************
 * Format Time
 *
 */

export function formatTime(date: Date, formatString?: string): string {
  if (formatString) {
    return format(date, formatString)
  }

  if (isToday(date)) {
    return `${format(date, 'hh:mm')}`
  }

  if (isYesterday(date)) {
    return `Hôm qua ${format(date, 'hh:mm')}`
  }

  if (isSameYear(date, new Date())) {
    return `${format(date, 'dd')} tháng ${format(date, 'MM hh:mm')}`
  }

  return format(date, 'dd/MM/yyyy')
}
