import { Divider } from 'antd'
import React from 'react'
import { IoSettingsOutline, IoShareSocialOutline } from 'react-icons/io5'
import { Outlet } from 'react-router-dom'

import SecondSidebar from '@/components/SecondSidebar'

const SettingsLayout: React.FC = () => {
  const sidebarItems = [
    { key: 'general', label: 'General', icon: <IoSettingsOutline /> },
    { key: 'others', label: 'Others', icon: <IoShareSocialOutline /> }
  ]

  return (
    <main>
      <div className='container flex p-20'>
        <SecondSidebar items={sidebarItems} basePath='/settings' />
        <Divider type='vertical' className='mx-5 h-auto self-stretch' />
        <div className='flex-1'>
          <Outlet />
        </div>
      </div>
    </main>
  )
}

export default SettingsLayout
