// import { sortBy } from 'lodash'
import { Flex, Modal } from 'antd'
import { sortBy } from 'lodash'
import React, { useEffect } from 'react'
import { BsCart3 } from 'react-icons/bs'
import { useDispatch, useSelector } from 'react-redux'
import type { NavigateFunction } from 'react-router-dom'
import { Outlet, useNavigate, useParams } from 'react-router-dom'

import PageHeader from '@/components/PageHeader'
import type { RootState } from '@/store'

import ModalAddPlan from './partials/plan/components/ModalAddPlan'
import PlanItem from './partials/plan/PlanItem'
import { fetchPlans } from './reducers/planReducer'

const PlansIndex = () => {
  const dispatch = useDispatch<any>()
  const navigate: NavigateFunction = useNavigate()
  const params = useParams()
  const plans = useSelector((state: RootState) => state.plan.items)

  const handleClose = () => {
    navigate('/plans')
  }

  const fetchData = async () => {
    try {
      await dispatch(fetchPlans()).unwrap()
    } catch (error) {}
  }

  /*
   *****************************************
   *
   *
   */

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <div className='flex flex-1 flex-col'>
      <PageHeader icon={<BsCart3 size={18} />} title='Bảng giá'>
        <ModalAddPlan onClose={() => {}} />
      </PageHeader>

      <div className='container pb-8 pt-10'>
        <Flex gap={32} vertical>
          {sortBy(plans, ['position']).map((item: any) => (
            <PlanItem key={item.id} info={item} />
          ))}
        </Flex>
      </div>

      <Modal title='Edit Package' className='top-10' width={440} footer={false} onCancel={handleClose} open={!!params.packageId}>
        <div className='min-h-[240px]'>
          <Outlet />
        </div>
      </Modal>
    </div>
  )
}

export default PlansIndex
