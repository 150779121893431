import type { FC } from 'react'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import type { NavigateFunction } from 'react-router-dom'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'

import { updateLastRoute } from '@/common/reducers/cacheReducer'
import { fetchMe } from '@/common/reducers/meReducer'
import { logout } from '@/modules/auth/reducers/authReducer'
import type { RootState } from '@/store'

import Header from './Header'

const Layout: FC = () => {
  const location = useLocation()
  const navigate: NavigateFunction = useNavigate()

  const dispatch = useDispatch<any>()
  const isLoggedIn = useSelector((state: RootState) => state.auth.isLoggedIn)

  const [showSidebar, setShowSidebar] = useState(isLoggedIn)

  const isLoggedInState = useRef<boolean>(isLoggedIn)
  const guestRoutes = ['/oauth/google', '/login', '/register', '/confirm-email', '/reset-password']

  const checkShowSidebar = () => {
    if (isLoggedIn && !location.pathname.startsWith('/editor')) {
      setShowSidebar(true)
    } else {
      setShowSidebar(false)
    }
  }

  const handleFetchMe = async () => {
    try {
      await dispatch(fetchMe()).unwrap()
    } catch (error: any) {
      if (error === 'Forbiden') {
        await dispatch(logout())
        navigate('/login')
      }
    }
  }

  useEffect(() => {
    if (isLoggedIn) {
      handleFetchMe()
    }

    if (!guestRoutes.includes(location.pathname)) {
      if (!isLoggedIn) {
        navigate('/login')
      }
    }
  }, [])

  useEffect(() => {
    if (isLoggedInState.current === true && isLoggedIn === false) {
      navigate('/login')
    }

    checkShowSidebar()
  }, [isLoggedIn])

  useEffect(() => {
    if (!guestRoutes.includes(location.pathname)) {
      if (isLoggedIn) {
        dispatch(updateLastRoute(location.pathname))
      } else {
        navigate('/login')
      }
    }

    checkShowSidebar()
  }, [location])

  return (
    <div className='flex min-h-screen flex-col'>
      {showSidebar && <Header />}

      <div className={`flex flex-1 flex-col ${isLoggedIn ? 'pt-[60px]' : ''}`}>
        <Outlet />
      </div>
    </div>
  )
}

export default Layout
