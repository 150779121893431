import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import api from '@/common/api'

import type { EPackageBillingCycle } from '../common/enum'
import { addPackage as addPackageToPlan, deletePackage as deletePackageFromPlan, setPackages, updatePackage as updatePackageInPlan } from './planReducer'

export interface PackageState {
  total: number
  items: any[]
  filter: {
    page: number
    limit: number
    sorts?: string
    search?: string
    createdAt?: string
  }
}

const initialState: PackageState = {
  total: 0,
  items: [],
  filter: {
    page: 1,
    limit: 100,
    sorts: '-createdAt',
    search: '',
    createdAt: ''
  }
}

/*
 *****************************************
 *
 *
 */
type PackageFetchDto = {
  planId: string
}

export const fetchPackages = createAsyncThunk('package/list', async (payload: PackageFetchDto, { rejectWithValue, dispatch }) => {
  try {
    const rs = (await api.get('packages', {
      params: payload
    })) as any

    if (rs) {
      dispatch(setPackages({ planId: payload.planId, packages: rs?.items }))
      return rs
    }

    return {}
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

/*
 *****************************************
 *
 *
 */
type PackageCreateDto = {
  planId: string
  price: number
  billingCycle: EPackageBillingCycle
  operations: number
}

export const createPackage = createAsyncThunk('package/create', async (payload: PackageCreateDto, { rejectWithValue, dispatch }) => {
  try {
    const rs: any = await api.post('packages', payload)
    if (rs) {
      dispatch(addPackageToPlan({ planId: payload.planId, packageData: rs }))
      return rs
    }
    return {}
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

/*
 *****************************************
 *
 *
 */

export const getPackage = createAsyncThunk('package/get', async (id: string, { rejectWithValue }) => {
  try {
    const rs: any = await api.get(`packages/${id}`)

    return { id: rs.id, ...rs }
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

/*
 *****************************************
 *
 *
 */
type PackageUpdateDto = {
  id: string
  price: number
  billingCycle: EPackageBillingCycle
  operations: number
}

export const updatePackage = createAsyncThunk('package/update', async (payload: PackageUpdateDto, { rejectWithValue, dispatch }) => {
  try {
    const { id, ...updateInfo } = payload
    const rs: any = await api.put(`packages/${id}`, updateInfo)
    if (rs) {
      dispatch(updatePackageInPlan({ planId: rs.planId, packageData: rs }))
      return { id, ...rs }
    }
    return {}
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

/*
 *****************************************
 *
 *
 */

export const deletePackage = createAsyncThunk('package/delete', async (data: any, { rejectWithValue, dispatch }) => {
  try {
    await api.delete(`packages/${data.id}`)

    dispatch(deletePackageFromPlan({ planId: data.planId, packageId: data.id }))
    return {
      id: data.id,
      planId: data.planId
    }
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

export const packageSlice = createSlice({
  name: 'package',

  initialState,

  reducers: {
    reset: (state) => {
      state.total = initialState.total
      state.items = initialState.items
      state.filter = JSON.parse(JSON.stringify(initialState.filter))
    },

    updateFilter(state, { payload }) {
      state.filter = { ...state.filter, ...payload }
    }
  },

  extraReducers: (builder) => {
    builder.addCase(fetchPackages.fulfilled, (state, action) => {
      const { total, items } = action.payload as any

      state.items = items
      state.total = total
    })
  }
})

export const { reset, updateFilter } = packageSlice.actions
export default packageSlice.reducer
