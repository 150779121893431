import { Button, Descriptions, Divider, Flex, message, Modal, Spin } from 'antd'
import { format } from 'date-fns'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import { getEnumKeyName } from '@/utils/converter'
import { formatMoney } from '@/utils/formater'

import { getPaymentMethodString } from './common/converter'
import { EInvoiceStatus } from './common/enum'
import { approveInvoice, cancelInvoice, getInvoice, updateLocal } from './reducers/invoiceReducer'

const InvoiceDetail: React.FC = () => {
  const dispatch = useDispatch<any>()

  const params = useParams()
  const [info, setInfo] = useState<any>({})
  const [loading, setLoading] = useState<boolean>(false)
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false)

  const [messageApi, contextHolder] = message.useMessage()
  const [errorMessage, setErrorMessage] = useState<string>('')

  const fetchInfo = async () => {
    try {
      if (params.id) {
        const rs = await dispatch(
          getInvoice({
            invoiceId: params.id
          })
        ).unwrap()

        setInfo(rs)
      }
    } catch (error) {}
  }

  useEffect(() => {
    if (params.id) {
      fetchInfo()
    }
  }, [params.id])

  useEffect(() => {
    if (errorMessage) {
      messageApi.error(errorMessage)
    }
  }, [errorMessage])

  const handleApprove = async () => {
    try {
      setLoading(true)

      const { id } = info

      const rs: any = await dispatch(approveInvoice(id)).unwrap()

      if (rs?.id && rs?.status) {
        dispatch(updateLocal(rs))
        setInfo(rs)
        messageApi.success('Approve successfully')
      }
    } catch (error: any) {
      const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
      setErrorMessage(resMessage)
    } finally {
      setLoading(false)
    }
  }

  const handleCancel = async () => {
    try {
      setLoading(true)

      const { id } = info

      const rs: any = await dispatch(cancelInvoice(id)).unwrap()

      if (rs?.id && rs?.status) {
        dispatch(updateLocal(rs))
        setInfo(rs)
        messageApi.success('Cancel invoice successfully')
      }
      setIsModalVisible(false)
    } catch (error: any) {
      const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
      setErrorMessage(resMessage)
    } finally {
      setLoading(false)
    }
  }

  const formatDate = (date: string) => format(new Date(date), 'dd/MM/yyyy HH:mm:ss')

  return (
    <div>
      {contextHolder}
      {!loading && info?.id ? (
        <div>
          {/* modal confirm cancel invoice */}
          <Modal
            title='Confirm cancel invoice'
            open={isModalVisible}
            onOk={handleCancel}
            onCancel={() => setIsModalVisible(false)}
            okText='Yes, Cancel'
            cancelText='No'
            cancelButtonProps={{ className: 'inline-flex' }}
            okButtonProps={{ className: 'inline-flex ms-4' }}
          >
            <p>Are you sure you want to cancel this invoice?</p>
          </Modal>

          <Descriptions title='Customer'>
            <Descriptions.Item label='Name'>{info.name}</Descriptions.Item>
            <Descriptions.Item label='Email'>{info.email}</Descriptions.Item>
          </Descriptions>
          <Divider className='mt-0' />
          <Descriptions title='Invoice'>
            <Descriptions.Item label='Code'>{info.code}</Descriptions.Item>
            <Descriptions.Item label='Created At' span={2}>
              {formatDate(info.createdAt)}
            </Descriptions.Item>
            <Descriptions.Item label='Status'>{getEnumKeyName(EInvoiceStatus, info.status)}</Descriptions.Item>
            <Descriptions.Item label='Paid date' span={2}>
              {info.datePaid ? formatDate(info.paidDate) : 'N/A'}
            </Descriptions.Item>
            <Descriptions.Item label='Payment method' span={3}>
              {getPaymentMethodString(info.paymentMethod)}
            </Descriptions.Item>
          </Descriptions>

          <Descriptions bordered column={1} labelStyle={{ width: '30%' }}>
            <Descriptions.Item label='Subtotal'>{formatMoney(info.subTotal)} đ</Descriptions.Item>
            <Descriptions.Item label='Discount'>{info.discount}</Descriptions.Item>
            <Descriptions.Item label='VAT'>{info.vat}</Descriptions.Item>
            <Descriptions.Item label='Total'>{formatMoney(info.total)} đ</Descriptions.Item>
          </Descriptions>

          {(info.status === EInvoiceStatus.UNPAID || info.status === EInvoiceStatus.PROCESSING) && (
            <Flex justify='end' gap='middle' className='mt-4'>
              <Button onClick={() => setIsModalVisible(true)}>Cancel</Button>

              <Button type='primary' onClick={handleApprove}>
                Approve
              </Button>
            </Flex>
          )}
        </div>
      ) : (
        <div className='flex h-full flex-1 flex-col  center'>
          <Spin />
        </div>
      )}
    </div>
  )
}

export default InvoiceDetail
