export enum EPlanType {
  ONETIME = 1,
  MONTHLY = 2,
  YEARLY = 3
}

export enum EPackageBillingCycle {
  ONETIME = 1,
  MONTHLY = 2,
  YEARLY = 3
}
