// import HomeWidgetFolder from 'modules/folder/components/HomeWidgetFolder'
import { Card } from 'antd'
import type { FC } from 'react'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import type { NavigateFunction } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'

import type { RootState } from '@/store'

const HomePage: FC = () => {
  const navigate: NavigateFunction = useNavigate()
  const isLoggedIn = useSelector((state: RootState) => state.auth.isLoggedIn)

  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/login')
    }
  }, [])

  return (
    <main className='flex flex-col bg-for-bg'>
      <div className='container pt-6'>
        <Card>
          <div className='text-xl font-bold'>Dashboard</div>
          <div className='mt-4'>This is the dashboard page. You can add more widgets here.</div>
        </Card>
      </div>
    </main>
  )
}

export default HomePage
