import type { TablePaginationConfig, TableProps } from 'antd'
import { Drawer, Table } from 'antd'
import { isEmpty } from 'lodash'
import React, { useEffect } from 'react'
import { BsPerson } from 'react-icons/bs'
import { LiaUserSolid } from 'react-icons/lia'
import { useDispatch, useSelector } from 'react-redux'
import type { NavigateFunction } from 'react-router-dom'
import { Outlet, useNavigate, useParams } from 'react-router-dom'

import PageHeader from '@/components/PageHeader'
import StatisticCard from '@/components/StatisticCard'
import type { RootState } from '@/store'
import { getEnumKeyName } from '@/utils/converter'
import { formatTime } from '@/utils/formater'

import { EUserStatus } from '../common/enum'
import FilterByTime from '../components/FilterByTime'
import Search from '../components/Search'
import { fetchUsers, updateFilter } from '../reducers/userReducer'

function UsersLayout() {
  const navigate: NavigateFunction = useNavigate()
  const params = useParams()

  const dispatch = useDispatch<any>()
  const total = useSelector((state: RootState) => state.user.total)
  const items = useSelector((state: RootState) => state.user.items)
  const filter = useSelector((state: RootState) => state.user.filter)
  const meta = useSelector((state: RootState) => state.user.meta)

  /*
   *****************************************
   *
   *
   */

  const fetchData = async () => {
    try {
      await dispatch(fetchUsers()).unwrap()
    } catch (error) {}
  }

  useEffect(() => {
    // fetchData()
  }, [])

  useEffect(() => {
    fetchData()
  }, [filter])

  /*
   *****************************************
   *
   *
   */

  const onClickRow = (record: any) => {
    navigate(`/users/${record.id}`)
  }

  const shouldOpen = () => {
    if (params.id) {
      return true
    }
    return false
  }

  const handleClose = () => {
    navigate(`/users`)
  }

  const handleTableChange = (pagination: TablePaginationConfig, filters: any, sorter: any) => {
    let newFilter = {}
    if (sorter.field && sorter.order) {
      newFilter = Object.assign(newFilter, {
        sorts: sorter.order === 'ascend' ? `${sorter.field}` : `-${sorter.field}`
      })
    }

    if (!isEmpty(filters.status)) {
      newFilter = Object.assign(newFilter, {
        status: `in:${filters.status.join(',')}`,
        page: 1
      })
    } else if (filters.status === null) {
      newFilter = Object.assign(newFilter, {
        status: undefined,
        page: 1
      })
    }

    if (pagination?.current) {
      newFilter = Object.assign(newFilter, {
        page: pagination.current
      })
    }

    dispatch(updateFilter(newFilter))
  }

  /*
   *****************************************
   *
   *
   */

  const sortDirections: any = ['ascend', 'descend', 'ascend']

  const columns: TableProps<any>['columns'] = [
    {
      title: 'Name',
      dataIndex: 'fullname'
    },
    {
      title: 'Email',
      dataIndex: 'email'
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: '160px',
      filters: [
        { text: 'Inactive', value: EUserStatus.INACTIVE },
        { text: 'Active', value: EUserStatus.ACTIVE },
        { text: 'Spam', value: EUserStatus.SPAM },
        { text: 'Banned', value: EUserStatus.BANNED },
        { text: 'Trash', value: EUserStatus.TRASH }
      ],
      render: (status: string) => <div>{getEnumKeyName(EUserStatus, status)}</div>
    },
    {
      title: 'Created At',
      sorter: true,
      dataIndex: 'createdAt',
      width: '200px',
      render: (val: string) => <div className=''>{formatTime(new Date(val))}</div>
    }
  ]

  const googleUserPercent = () => {
    if (meta?.newUserCount && meta?.googleUserCount) {
      return (meta.googleUserCount / meta.newUserCount) * 100
    }

    return '0'
  }

  const newUserPercent = () => {
    if (meta?.newUserCount && meta?.totalCount) {
      return (meta.newUserCount / meta.totalCount) * 100
    }

    return '0'
  }

  /*
   *****************************************
   *
   *
   */

  return (
    <main className='flex flex-col pb-6'>
      <PageHeader icon={<BsPerson size={20} />} title='Người dùng' />

      <div className='container mt-8 flex flex-row gap-4'>
        <Search />

        <div className='flex-1' />

        <FilterByTime />
      </div>

      <div className='container mt-6 grid grid-cols-4 gap-4'>
        <StatisticCard value={meta?.totalCount || 0} title='Total users' icon={<LiaUserSolid size={20} />} />
        <StatisticCard value={meta?.newUserCount || 0} title='New users' changePercent={newUserPercent()} icon={<LiaUserSolid size={20} />} />
        <StatisticCard value={meta?.googleUserCount || 0} title='Google Account' changePercent={googleUserPercent()} icon={<LiaUserSolid size={20} />} />
        <StatisticCard value={meta?.googleUserCount || 0} title='Google Account' changePercent={googleUserPercent()} icon={<LiaUserSolid size={20} />} />
      </div>

      <div className='container mt-8'>
        <Table
          onRow={(record) => {
            return {
              onClick: () => {
                onClickRow(record)
              }
            }
          }}
          rowClassName='cursor-pointer'
          dataSource={items}
          rowKey='id'
          columns={columns}
          showSorterTooltip={false}
          sortDirections={sortDirections}
          onChange={handleTableChange}
          pagination={{ pageSize: filter.limit, current: filter.page, total }}
        />
      </div>

      <Drawer title='Edit User' width={500} placement='right' onClose={handleClose} destroyOnClose open={shouldOpen()}>
        {shouldOpen() && <Outlet />}
      </Drawer>
    </main>
  )
}

export default UsersLayout
