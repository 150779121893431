import { Button } from 'antd'
import type { ReactNode } from 'react'
import React, { useEffect } from 'react'
import type { NavigateFunction } from 'react-router-dom'
import { useLocation, useNavigate } from 'react-router-dom'

interface SidebarItem {
  key: string
  label: string
  icon?: ReactNode
}
interface SecondSidebarProps {
  items: SidebarItem[]
  basePath: string
}

const SidebarButton: React.FC<{ label: string; isActive: boolean; onClick: () => void; icon?: ReactNode }> = ({ label, isActive, onClick, icon }) => {
  return (
    <Button
      type='text'
      size='large'
      icon={icon ? <div className='center'>{icon}</div> : null}
      className={`mb-2 w-full items-center justify-start text-left hover:bg-gray-200 ${isActive ? 'bg-gray-200' : 'bg-gray-50'}`}
      onClick={onClick}
    >
      {label}
    </Button>
  )
}

const SecondSidebar: React.FC<SecondSidebarProps> = ({ items, basePath }) => {
  const navigate: NavigateFunction = useNavigate()
  const location = useLocation()
  const [activeTab, setActiveTab] = React.useState(items[0]?.key || '')

  const handleTabChange = (key: string) => {
    setActiveTab(key)
    if (key === '') {
      navigate(basePath)
    } else {
      navigate(`${basePath}/${key}`)
    }
  }

  useEffect(() => {
    const currentTab = location.pathname.split('/').pop()

    if (!currentTab || !items.some((item) => item.key === currentTab)) {
      setActiveTab(items[0]?.key)
    } else {
      setActiveTab(currentTab)
    }
  }, [location, items])

  return (
    <aside className='w-[200px]'>
      {items.map((item) => (
        <SidebarButton key={item.key} label={item.label} isActive={activeTab === item.key} icon={item.icon} onClick={() => handleTabChange(item.key)} />
      ))}
    </aside>
  )
}

export default SecondSidebar
