import { isNaN } from 'lodash'

export async function convertBase64toFile(dataUrl: string, fileName: string) {
  const response = await fetch(dataUrl)
  const blob = await response.blob()
  return new File([blob], fileName, { type: blob.type })
}

export async function convertImageToBase64(file: any) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.onloadend = () => {
      resolve(reader.result)
    }
    reader.onerror = reject
    reader.readAsDataURL(file)
  })
}

/*
 *****************************************
 * Enum
 *
 */

export function convertEnumToArray(enumObj: any): any[] {
  return Object.keys(enumObj)
    .filter((k) => isNaN(Number(k)))
    .map((key) => ({
      value: enumObj[key],
      label: key
    }))
}

export function convertEnumToSelectOptions(enumObj: any): any[] {
  return Object.keys(enumObj)
    .filter((k) => isNaN(Number(k)))
    .map((key) => ({
      value: enumObj[key],
      label: key
        .split('_')
        .map((word) => word.charAt(0) + word.slice(1).toLowerCase())
        .join(' ')
    }))
}

export function getEnumKeyName(enumObj: any, enumVal: any): any[] {
  const arr = convertEnumToSelectOptions(enumObj)

  const obj = arr.find((item) => item.value === enumVal)

  return obj.label
}
