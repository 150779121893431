import { Input } from 'antd'
import React, { useEffect, useRef } from 'react'

type Props = {
  placeholder?: string
  className?: string
  onChange: (val: string) => void
}

const SearchInput = ({ onChange, placeholder, className }: Props) => {
  const timerRef = useRef<any>()

  // const [val, setVal] = useState<string>('')

  useEffect(() => {
    return () => {
      if (timerRef.current) {
        try {
          clearTimeout(timerRef.current)
        } catch (error) {}
      }
    }
  }, [])

  const handleOnChange = (evt: any) => {
    if (timerRef.current) {
      clearTimeout(timerRef.current)
    }

    timerRef.current = setTimeout(() => {
      onChange(evt.target.value)
    }, 500)
  }

  return <Input placeholder={placeholder} onChange={handleOnChange} className={className} />
}

export default SearchInput
