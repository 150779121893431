export enum EInvoiceStatus {
  UNPAID = 1,
  PROCESSING = 2,
  PAID = 3,
  CANCELED = 4,
  OVERDUE = 5
}

export enum EPaymentMethod {
  BANK = 1,
  CASH = 2,
  MOMO = 3
}
