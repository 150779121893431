import { Button, Form, Input, notification } from 'antd'
import { isEmpty } from 'lodash'
import type { FC } from 'react'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { updateProfile } from '@/common/reducers/meReducer'
import type IUser from '@/common/types/user.type'
import { getUpdatedDiff } from '@/common/utils'
import type { RootState } from '@/store'

const ProfileIndex: FC = () => {
  const [api, contextHolder] = notification.useNotification()

  const authUser: IUser = useSelector((state: RootState) => state.auth.user)
  const dispatch = useDispatch<any>()
  const [loading, setLoading] = useState(false)
  const [disabled, setDisabled] = useState(true)

  const onFinish = async (formData: any) => {
    const updatedInfo = getUpdatedDiff(authUser, formData)

    if (isEmpty(updatedInfo)) {
      return
    }

    try {
      setLoading(true)
      await dispatch(updateProfile(updatedInfo)).unwrap()

      api.success({
        message: `Notification`,
        description: 'Update your profile successfully',
        placement: 'topRight',
        duration: 2
      })
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  const handleValuesChange = (changedValues: any, allValues: any) => {
    const updatedInfo = getUpdatedDiff(authUser, allValues)

    if (isEmpty(updatedInfo)) {
      setDisabled(true)
    } else {
      setDisabled(false)
    }
  }

  return (
    <div>
      {contextHolder}

      <div className='max-w-lg'>
        <Form className='' name='me' onValuesChange={handleValuesChange} initialValues={authUser} onFinish={onFinish} layout='vertical' autoComplete='off'>
          <Form.Item label='Email' name='email'>
            <Input readOnly />
          </Form.Item>

          <Form.Item label='Full Name' name='fullname' rules={[{ required: true, message: 'Please input your full name!' }]}>
            <Input />
          </Form.Item>

          <Form.Item className='mt-10'>
            <Button type='primary' htmlType='submit' disabled={disabled} loading={loading}>
              Save
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  )
}

export default ProfileIndex
