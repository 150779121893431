import type { ReactNode } from 'react'
import React from 'react'
import { Link, useLocation } from 'react-router-dom'

type Props = {
  name: string
  to: string
  icon: ReactNode
  className?: string
}

const MenuItem = ({ name, to, icon, className }: Props) => {
  const location = useLocation()

  const isActive = () => {
    if (to === '/') {
      return location.pathname === to
    }
    return location.pathname.startsWith(`${to}`)
  }

  return (
    <div className=''>
      <Link
        to={isActive() ? location.pathname : to}
        className={`drag_item flex w-full cursor-pointer select-none flex-row items-center justify-start rounded-md pl-1 pr-4 transition duration-75 ease-in ${
          isActive() ? 'bg-black/10' : 'hover:bg-black/5'
        } ${className}`}
      >
        {/* {isActive() && <div className='absolute left-0 h-10 w-[6px] rounded-r bg-primary' />} */}

        <div className='h-8 w-8 rounded-lg center'>{icon}</div>
        <div className='text-base text-main'>{name}</div>
      </Link>
    </div>
  )
}

export default MenuItem
