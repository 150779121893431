import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import api from '@/common/api'
import type { RootState } from '@/store'

import type { IAppInfo } from '../common/interface'

interface IState {
  total: number
  items: IAppInfo[]
  filter: {
    search?: string
  }
}

const initialState: IState = {
  total: 0,
  items: [],
  filter: {
    search: ''
  }
}

/*
 *****************************************
 *
 *
 */

export const fetchApps = createAsyncThunk('apps/list', async (_, { getState, rejectWithValue }) => {
  const state = getState() as RootState

  try {
    const params = {
      ...state.app.filter
    }

    if (params.search === '') {
      delete params.search
    }

    return await api.get('apps', {
      params
    })
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

/*
 *****************************************
 *
 *
 */

type AppGetDto = {
  id: string
}

export const getApp = createAsyncThunk('apps/get', async (payload: AppGetDto, { rejectWithValue }) => {
  try {
    const rs: any = await api.get(`apps/${payload.id}`)

    return { id: rs.id, ...rs }
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

/*
 *****************************************
 *
 *
 */

export const logSlice = createSlice({
  name: 'apps',

  initialState,

  reducers: {
    updateFilter(state, { payload }) {
      state.filter = { ...state.filter, ...payload }
    }
  },

  extraReducers: (builder) => {
    builder.addCase(fetchApps.fulfilled, (state, action) => {
      const { total, items } = action.payload as any

      state.items = items
      state.total = total
    })
  }
})

export const { updateFilter } = logSlice.actions
export default logSlice.reducer
