import type { FC } from 'react'
import React, { createContext, useMemo } from 'react'
import { useSelector } from 'react-redux'

import type { RootState } from '@/store'

export type LangContextType = {
  lang: (en: string, vi: string) => string
}

export const LangContext = createContext<LangContextType | undefined>(undefined)

type LangProviderProps = {
  children: React.ReactNode
}

const LangProvider: FC<LangProviderProps> = ({ children }: LangProviderProps) => {
  const user = useSelector((state: RootState) => state.auth.user)

  const handleLang = (en: string, vi: string) => {
    return user?.lang === 'en' ? en : vi
  }

  const contextValue = useMemo(() => ({ lang: handleLang }), [])

  return <LangContext.Provider value={contextValue}>{children}</LangContext.Provider>
}

export default LangProvider
