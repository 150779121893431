import '@dotlottie/player-component'

import React from 'react'

type Props = {
  className?: string
  json?: boolean
  name: string
}

const Lottie = ({ name, className, json = false }: Props) => {
  return (
    <div className={className}>
      <dotlottie-player src={`/lottie/${name}.${json ? 'json' : 'lottie'}`} autoplay loop style={{ height: '100%', width: '100%' }} />
    </div>
  )
}

export default Lottie
