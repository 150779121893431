import type { ReactNode } from 'react'
import React from 'react'
import { IoArrowBackOutline } from 'react-icons/io5'

type Props = {
  icon?: ReactNode
  onClickIcon?: () => void
  title: string
  children?: ReactNode
}

const PageHeader = ({ icon, onClickIcon, title, children }: Props) => {
  return (
    <div className='z-10 bg-gray-100 py-4 shadow'>
      <div className='container relative flex h-full flex-row items-center justify-between'>
        <div className='flex flex-row items-center gap-3'>
          <div
            className='h-9 w-9 cursor-pointer rounded bg-white shadow center hover:shadow-card2'
            onClick={() => {
              if (onClickIcon) {
                onClickIcon()
              }
            }}
          >
            {icon || <IoArrowBackOutline size={18} />}
          </div>

          <div className='text-[26px] text-main'>{title}</div>
        </div>

        <div>{children}</div>
      </div>
    </div>
  )
}

export default PageHeader
