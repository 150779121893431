import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import api from '@/common/api'
import { setUser, updateAuthUser } from '@/modules/auth/reducers/authReducer'
import { EUserPermissions } from '@/modules/users/common/enum'

export interface AuthUserState {
  // menus: any[]
}

const initialState: AuthUserState = {
  // menus: []
}

/*
 *****************************************
 *
 *
 */

export const fetchMe = createAsyncThunk('me/fetchMe', async (_, { rejectWithValue, dispatch }) => {
  try {
    const data: any = await api.get('me')

    if (!data?.permissions.includes(EUserPermissions.ISADMIN)) {
      return rejectWithValue('Forbiden')
    }

    dispatch(setUser(data))
    return {}
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

/*
 *****************************************
 * Settings
 *
 */

export const updateProfile = createAsyncThunk('me/updateProfile', async (payload: any, { rejectWithValue, dispatch }) => {
  try {
    const data = await api.put('me/settings', payload)

    dispatch(updateAuthUser(data))
    return {}
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

export const changePassword = createAsyncThunk('me/change-password', async (payload: any, { rejectWithValue, dispatch }) => {
  try {
    const data = await api.put('me/change-password', payload)

    dispatch(updateAuthUser(data))
    return {}
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

/*
 *****************************************
 *
 *
 */

export const meSlice = createSlice({
  name: 'me',

  initialState,

  reducers: {},

  extraReducers: (_builder) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    // builder.addCase(fetchMenus.fulfilled, (state, action) => {
    //   const { items } = action.payload as any
    //   state.menus = items
    // })
  }
})

export default meSlice.reducer
