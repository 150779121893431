import { Segmented } from 'antd'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import type { RootState } from '@/store'
import { getEnumKeyName } from '@/utils/converter'

import { EInvoiceStatus } from '../common/enum'
import { updateFilter } from '../reducers/invoiceReducer'

const FilterByStatus: React.FC = () => {
  const dispatch = useDispatch<any>()
  const filterState = useSelector((state: RootState) => state.invoice.filter)
  const meta = useSelector((state: RootState) => state.invoice.meta)
  const [selectedStatus, setSelectedStatus] = useState<any>(filterState?.status || '')

  const handleStatusChange = (value: string | number) => {
    setSelectedStatus(value as EInvoiceStatus)
    dispatch(updateFilter({ status: value, page: 1 }))
  }

  const statusOptions = meta?.stats?.map((item: any) => ({
    label: (
      <div className='gap-2 center'>
        <span>{getEnumKeyName(EInvoiceStatus, item.status)}</span>
        <span className='text-sm opacity-50'>{item.count}</span>
      </div>
    ),
    value: item.status
  }))

  statusOptions.unshift({
    label: (
      <div className='gap-2 center'>
        <span>All</span>
        <span className='text-sm opacity-50'>{meta?.totalCount}</span>
      </div>
    ),
    value: ''
  })

  return <Segmented size='middle' options={statusOptions} onChange={handleStatusChange} value={selectedStatus} />
}

export default FilterByStatus
