import { Divider } from 'antd'
import type { FC } from 'react'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import type { NavigateFunction } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'

import GoogleSignInButton from '@/components/GoogleSignInButton'
import Logo from '@/components/Logo'
import type { RootState } from '@/store'

import { login } from './reducers/authReducer'

const LoginPage: FC = () => {
  const dispatch = useDispatch<any>()
  const navigate: NavigateFunction = useNavigate()
  const isLoggedIn = useSelector((state: RootState) => state.auth.isLoggedIn)

  /*
   *****************************************
   *
   *
   */

  const handleLoginWithCode = async (code: string) => {
    try {
      await dispatch(login({ code })).unwrap()
      navigate('/')
    } catch (error: any) {
      console.log('handleLoginWithCode ~ error:', error)
    }
  }

  /*
   *****************************************
   *
   *
   */

  useEffect(() => {
    if (isLoggedIn) {
      navigate('/')
    } else {
      const search = window.location.search.replace(/\+/g, '%2B')
      const code = new URLSearchParams(search).get('code')

      if (code) {
        handleLoginWithCode(code)
      }
    }
  }, [])

  return (
    <div className='flex min-h-screen w-full flex-col items-center overflow-y-auto bg-[#fafafa] py-6 md:py-16'>
      <div className='px flex w-full max-w-full flex-col gap-4 px-4 md:w-[420px]'>
        <div className='flex flex-row items-center px-6 pb-5 center'>
          <Logo type='full' className='h-[48px] text-green-200' />
        </div>

        <div className='flex flex-col rounded-lg border border-primary-200 bg-white px-4 py-5 shadow md:px-8 md:pb-12 md:pt-8'>
          <div className='text-3xl font-semibold text-base-1 md:text-4xl'>Sign in</div>
          <div className='mt-4 text-sm font-semibold text-base-1 md:mt-6'>Sign in with open account</div>

          <Divider dashed className='my-4' />

          <div className='flex flex-col gap-5'>
            <GoogleSignInButton />

            {/* <ZobiteAuthLink>Login with Zobite account</ZobiteAuthLink> */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default LoginPage
